<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'updated_at'"
        :sortOrder="'desc'"
        :extraParam="'pending'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :showAddButton="false"
        :showDropdown="false"
        ref="overview"
    >

        <template #extrasearchoptions>
            <div style="width: 25rem">
                <FormItem type="select" v-model="typeSelect" :options="statusTypes" :label="$t('overview.status')" @onchange="filterOnType" :placeholder="$t('form.all')" />
            </div>
            <img id="wclogo-5" width="250" src="https://nmtmainport.com.netvibestest.net/img/world-check-logo.png" />
        </template>
        <template #columns>
            <Column field="external_case_id" header="ID"></Column>
            <Column field="relation.name" :header="$t('overview.name')"></Column>
            <Column field="status" :header="$t('overview.status')" style="width: 7rem">
                <template #body="slotProps">
                    <span class="badge" :data-status="slotProps.data.status">
                        {{ $t('quote_status.' + slotProps.data.status) }}
                    </span>
                </template>
            </Column>
            <Column field="updated_at" :header="$t('overview.date')" style="width: 9rem">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.updated_at, 'shortDate')}}
                </template>
            </Column>
            <Column :header="$t('crm.hit')">
                <template #body="slotProps">
                    {{ getSelectedCaseLine(slotProps.data) }}
                </template>
            </Column>
        </template>
        <template #form>
            <WorldcheckForm ref="ItemForm" :itemData="item" />
        </template>
        <template #modal-footer>
            <img id="wclogo-2" src="https://nmtmainport.com.netvibestest.net/img/world-check-logo.png" />
            <FormItem type="download" id="downloadbutton" :label="$t('form.download')" startTag="fal fa-file-pdf" :service="download" />
        </template>
    </Overview>
    
    <dialog id="details-dialog">
        <form method="dialog" class="d-flex justify-content-between position-fixed">
            <h4></h4>
            <button class="btn" style="width: 2rem" @click="loading = false"><i class="fa fa-close"></i></button>
        </form>
        <div class="w-100 mt-5"></div>
    </dialog>

</template>

<script>
import worldcheckService from '@/services/WorldcheckService';
import Overview from '@/components/Overview';
import Column from '@/components/column';
import WorldcheckForm from '@/components/relation/WorldcheckForm';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        WorldcheckForm
    },
    data() {
        return {
            item: {},
            itemService: worldcheckService,
            itemFormRef: null,
            typeSelect: 'pending',
            statusTypes: [
                { id: 'accepted', name: this.$t('quote_status.accepted') },
                { id: 'pending', name: this.$t('quote_status.pending') },
                { id: 'declined', name: this.$t('quote_status.declined') }
            ],
            selectFields: [
                'id',
                'external_case_id',
                'status',
                'type',
                'relation_id',
                'file_name',
                'updated_at',
                {
                    "relation": [
                        'id',
                        'name'
                    ]
                },
                {
                    "worldcheckCaseLines": [
                        "id",
                        "selected",
                        "primary_name",
                        "worldcheck_case_id",
                        "categories"
                    ]
                }
            ]
        }
    },
    methods: {
        getSelectedCaseLine(worldcheck) {
            const result = worldcheck.worldcheck_case_lines.find(el => el.selected === true);
            return result ? (result.primary_name + ' - ' + result.categories) : '-';
        },
        filterOnType(e){
            this.$refs.overview.setExtraParams(e.target.value == this.$t('form.all') ? false : e.target.value);
        },
        download(){
            const item = this.$refs.ItemForm.worldcheck;
            worldcheckService.download(item.id).then(response => {
                let name = 'worldcheck-' + item.external_case_id + '-' + item.relation.name.toLowerCase().replaceAll(' ', '') + ".pdf";
                if (item.file_name) {
                    name = item.file_name
                }
                this.triggerDownload(response.data, name);
            }).catch(error => {
                this.toastError(error)
            });
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>
<style scoped>
#wclogo-5{
    position: absolute;
    top: 1.5rem;
    right: 1rem;
}
.savebutton{
    position: relative;
    left: calc(33% + .75rem);
}
#details-dialog{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    width: 50rem;
    padding: 0
}
#details-dialog > form{
    width: 48rem;
    background-color: white;
    border-bottom: 1px solid #ddd;
    padding: 1rem 1rem 0rem 1rem
}
#details-dialog > div{
    padding: 1rem
}
</style>
<style>
#details-dialog table {
    width: 100%;
    border-collapse: collapse;
}
#details-dialog table tr > td {
    border: 1px solid #333;
    padding: 5px;
}
#details-dialog table tr > td:first-child {
    width: 30%;
    font-weight: bold;
}
</style>